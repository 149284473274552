import React from "react";

const SvgLogo : React.FC = () => {
    return(
        <svg width="38" height="34" viewBox="0 0 38 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M24.6142 33.2453H13.4923C6.60359 33.2453 0.946411 28.0518 0.946411 21.7278V11.5175C0.946411 5.19348 6.60359 0 13.4923 0H24.6142C31.5029 0 37.1601 5.19348 37.1601 11.5175V21.7631C37.1601 28.0872 31.5029 33.2453 24.6142 33.2453Z" fill="#135EE5"/>
            <path d="M10.0674 14.5911V22.3637L24.1911 24.6954C24.1911 22.081 24.1911 19.4666 24.1911 16.8876L10.0674 14.5911Z" fill="#ECE71C"/>
            <path d="M14.6085 9.57422V17.3468L28.7322 19.6785C28.7322 17.0641 28.7322 14.4497 28.7322 11.8707L14.6085 9.57422Z" fill="#ECE71C"/> 
        </svg>
    );
}

export default SvgLogo;