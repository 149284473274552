import { Check, Dot } from "lucide-react";
import React, {useState} from "react";

type ToggleSwitchType = {
    initialState:boolean,
    onToggle?: (state: boolean) => void | null,
    disableToggle?: boolean,
}
const ToggleSwitch = ({initialState, onToggle = () => {}, disableToggle = false}:ToggleSwitchType) => {
    const [toggle, setToggle] = useState<boolean>(initialState);

    const handleToggle = () => {
        if (disableToggle) return;
        const newState = !toggle;
        setToggle(newState);
        onToggle(newState);
    }

    return (
        <div onClick={handleToggle} className={`relative grid ${!disableToggle && 'cursor-pointer'} content-center w-11 h-6 p-1 rounded-full ${toggle ? 'bg-primary justify-items-end' : 'bg-gray-300'}`}>
            <div className="grid p-1 bg-white rounded-full w-fit justify-items-center">
                {toggle ? <Check size={10} />  : <Dot size={10}/>}
            </div>
        </div>
    );
}

export default ToggleSwitch