import React, {useEffect, useState} from "react";
import { Link, useParams } from "react-router-dom";
import AuthLayout from "../../../components/layouts/auth";
import { ArrowDownLeft, ChevronLeft, Minus, Plus } from "lucide-react";
import LineLoader from "../../../components/loader/line-loader";
import ApiSupport from "../../../lib/api-support";
import { getTransactionColorAndName, getTransactionTypeName, numberWithCommas } from "../../../lib/helper-support";
import moment from "moment";
import GetTransactionLayout from "./partials/single-transaction-to-layout";
import Naira from "../../../components/naira";

const SingleTransaction : React.FC = () => {
    const [pageLoading, setPageLoading] = useState<boolean>(true);
    
    const {transactionId} = useParams<{transactionId:string}>();

    const [transaction, setTransaction] = useState<any>({});
   
    const getTransaction = async () => {
        setPageLoading(true);
        const transaction = await new ApiSupport().get(`transactions/${transactionId}`);
        if ( transaction === 'error' ){
            setPageLoading(false);
            return;
        }
        const transactionData = JSON.parse(transaction);
        setTransaction(transactionData);
        setPageLoading(false);
    }

    const getTransactionColor = () => {
        if ( transaction.status === 'success' ) return '#009C2C';
        if ( transaction.status === 'failed' ) return  '#FE4D97';
        return '#FFAB2B';
    }

    useEffect(()=> {
        getTransaction();
        window.scrollTo(0, 0);
    }, []);
    return (
        <AuthLayout>
            <div>
                {pageLoading && <LineLoader/>}
                {(!transaction.reference && !pageLoading) && (
                    <div>
                        <h1 className="text-[2em] font-[600] text-primary">Transaction Not Found</h1>
                        <Link to={'/transactions'} className="flex items-center mt-5 space-x-2 font-[400] text-gray-500 hover:underline">
                            <ChevronLeft size={13}/>
                            <span>back to transactions</span>
                        </Link>
                    </div>
                )}
                {(!pageLoading && transaction.reference) && (
                    <div>
                    <h2 className="text-[#8D8D8D] font-[600] text-[14px]">Transactions / details</h2>
                    <div className="mt-4 lg:gap-5 lg:flex">
                        <div className="lg:w-3/5">
                            <div className="flex items-center mb-10 space-x-2">
                                <Link to={'/transactions'}><ChevronLeft size={20} className="-ml-2"/></Link>
                                <h1 className="font-[600] text-tertiary text-[18px]">
                                    Cashir transaction details: {transactionId}
                                </h1>
                            </div>
                            <div className="grid gap-y-10">
                                <div className="flex items-center justify-between">
                                    <div className="font-[600] text-tertiary">Amount</div>
                                    <div className="flex items-center text-primary">
                                        {transaction.action === 'credit' && <Plus size={18} strokeWidth={3}/>}
                                        {transaction.action === 'debit' && <Minus size={18} strokeWidth={3}/>}
                                        <h2 className="text-[2em] font-[600]">
                                            {<Naira/>}{numberWithCommas(transaction.amount)}.<span className="text-[18px]">{numberWithCommas(transaction.amount, true, true)}</span>
                                        </h2>
                                    </div>
                                </div>
                                <div className="flex items-center justify-between">
                                    <div className="font-[600] text-tertiary">To</div>
                                    <div className="uppercase text-[#656565] text-[15px] text-right font-[600]">
                                        {<GetTransactionLayout transaction={transaction}/>}
                                    </div>
                                </div>
                                <div className="flex items-center justify-between">
                                    <div className="font-[600] text-tertiary">Sender</div>
                                    <div className="capitalize text-[#656565] text-[15px] text-right font-[600]">
                                    { ! transaction.sender ?  (<Minus/>) : (
                                        <div>
                                            <p>{transaction?.sender?.name}</p>
                                            <p className="text-[14px]">#{transaction?.sender?.username}</p>
                                        </div>
                                    ) }
                                    </div>
                                </div>
                                <div className="flex items-center justify-between">
                                    <div className="font-[600] text-tertiary">Status</div>
                                    <div style={{color:getTransactionColorAndName(transaction).color}} className={`flex items-center space-x-2 font-[700] bg-[#F3F3F3] justify-center py-2 px-5 rounded-full capitalize`}>
                                        <span style={{ background: getTransactionColorAndName(transaction).color }} className={`w-5 h-5 rounded-full text-white flex items-center justify-center`}>
                                            <ArrowDownLeft size={13}/>
                                        </span>
                                        <span>{getTransactionColorAndName(transaction).name}</span>
                                    </div>
                                </div>
                            </div>
                            <table className="w-full mt-16 font-[500]">
                                <tbody>
                                    <tr className="border-b border-[#C4C4C4]">
                                        <td className="py-3 text-tertiary">Transaction date</td>
                                        <td className="py-3 pl-6 text-[#656565]">{moment(transaction.created_at).format('MMM Do, YYYY h:mm a')}</td>
                                    </tr>
                                    <tr className="border-b border-[#C4C4C4]">
                                        <td className="py-3 text-tertiary">Reference ID</td>
                                        <td className="py-3 pl-6 text-[#656565]">{transaction.reference}</td>
                                    </tr>
                                    <tr className="border-b border-[#C4C4C4]">
                                        <td className="py-3 text-tertiary">Narrative</td>
                                        <td className="py-3 pl-6 text-[#656565]">{transaction.narration ?? transaction.remarks ?? <Minus/>}</td>
                                    </tr>
                                    <tr className="border-b border-[#C4C4C4]">
                                        <td className="py-3 text-tertiary">Transaction type</td>
                                        <td className="py-3 pl-6">
                                            <div className="font-[600] bg-[#F8FAFB] py-2 px-5 rounded-full text-tertiary border border-primary w-fit text-[13px]">
                                                {getTransactionTypeName(transaction)}
                                            </div>
                                        </td>
                                    </tr>
                                    {/* <tr>
                                        <td className="py-3 text-tertiary">Infrastructure type</td>
                                        <td className="py-3 pl-6">
                                            <div className="font-[600] bg-[#2DB744] py-2 px-5 rounded-full text-white w-fit text-[13px]">
                                                Anchor bank
                                            </div>
                                        </td>
                                    </tr> */}
                                </tbody>
                            </table>
                        </div>
                        <div className="mt-10 lg:pl-5 lg:w-2/5 lg:mt-0">
                            <div className="border border-[#8D8D8D] rounded-[30px] py-5 min-h-[15em] lg:min-h-[35em]">
                                <div className="px-10 text-tertiary font-[600]">Cash Balance History</div>
                                <div className="mt-5 overflow-x-auto">
                                    <table className="w-full">
                                        <tbody>
                                            <tr className="text-tertiary font-[600] text-[14px] bg-[#D9D9D980]">
                                                <td className="py-2 pl-10">User</td>
                                                <td className="py-2 pl-10">Before</td>
                                                <td className="py-2 pl-10">After</td>
                                                <td className="px-5 py-2"></td>
                                            </tr>
                                            {transaction.balance_history.map((transaction:any, index:number)=>(
                                                <tr key={index} className="border-b border-[C4C4C4] text-[14px] font-[600] text-[#656565]">
                                                    <td className="py-5 pl-10">{transaction.name}</td>
                                                    <td className="py-5 pl-10">{<Naira/>}{numberWithCommas(transaction.before??0)}</td>
                                                    <td className="py-5 pl-10">{<Naira/>}{numberWithCommas(transaction.after??0)}</td>
                                                    <td className="px-5 py-5">
                                                        <div style={{ color: transaction.type === 'credit' ? '#009C2C' : '#FE4D97' }} className="capitalize flex items-center px-5 font-[600] bg-[#F3F3F3] justify-center p-2 rounded-full text-[11px]">
                                                            <span>{transaction.type}</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                )}
            </div>
        </AuthLayout>
    );
}

export default SingleTransaction;