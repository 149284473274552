
const SuccessFlash = (message = 'Request processed successfully') => {
    SetNotification(message, 'success');
};

const ErrorFlash = (message = 'Something went wrong') => {
    SetNotification(message, 'error');
}

const SetNotification = (message:string, type:string) => {
    const errorSvg = `<svg data-v-14c8c335="" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-circle-alert lucide-icon customizable"><circle cx="12" cy="12" r="10"></circle><line x1="12" x2="12" y1="8" y2="12"></line><line x1="12" x2="12.01" y1="16" y2="16"></line></svg>`;
    const successSvg = `<svg data-v-14c8c335="" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-circle-check lucide-icon customizable"><circle cx="12" cy="12" r="10"></circle><path d="m9 12 2 2 4-4"></path></svg>`
    const flashNotification = document.createElement("div");
    flashNotification.id = "flashNotification";
    flashNotification.className = `fixed cursor-pointer flex space-x-2 shadow items-center z-[100] right-10 top-10 p-3 w-fit text-white rounded ${type === 'success' ? 'bg-green-500' : 'bg-red-500'}`;
    flashNotification.innerHTML += `${type === 'success' ? successSvg : errorSvg}`;
    flashNotification.innerHTML += `<div>${message}</div>`;
    document.body.appendChild(flashNotification);
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.text = `if("undefined"==typeof lade_notificationScript||null===lade_notificationScript){let e=document.currentScript;document.getElementById("flashNotification").addEventListener("click",function t(){document.getElementById("flashNotification"),this.removeEventListener("click",t),document.body.removeChild(this),document.body.removeChild(e)})}`;
    document.body.insertAdjacentElement('beforeend', script);

    setTimeout(() => {
        (script && document.body.contains(script)) && document.body.removeChild(script);
        (flashNotification && document.body.contains(flashNotification)) && document.body.removeChild(flashNotification);
    }, 3000);
}

export {
    SuccessFlash,
    ErrorFlash,
}