import { Minus, MoveDownLeft, MoveUpRight, PlusIcon } from "lucide-react"
import React from "react"

type MetricType = {
    percentage?: string,
    increase?: boolean,
    decrease?: boolean,
}

const MetricIndicator : React.FC<MetricType> = ({
    percentage,
    increase,
    decrease,
} : MetricType) => {
    return (
        <div style={{ color: increase ? '#2DB744' : decrease ? '#FE4D97' : '' }} className='text-[14px] flex items-center space-x-1'>
            {increase &&  (<span className="flex items-center space-x-1"><MoveUpRight size={14}/> <PlusIcon size={14}/></span>)} 
            {decrease && (<span className="flex items-center space-x-1"><MoveDownLeft size={14}/> <Minus size={14}/></span>)}
            {percentage}%
        </div>
    )
}

export default MetricIndicator