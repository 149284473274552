type InputType = {
    placeholder:string,
}

const SearchInput = ({ placeholder }: InputType): JSX.Element => {
    return (
        <div className='lg:w-[25em] bg-[#f4f6f8]  rounded-[5px] relative flex h-[39px] space-x-2 items-center px-5'>
            <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.99996 6L0.756958 1.757L2.17196 0.343002L4.99996 3.172L7.82796 0.343002L9.24296 1.757L4.99996 6Z" fill="#1F4173"/>
            </svg>
            <div className='relative w-full'>
                <input placeholder={placeholder} className='top-0 bottom-0 left-0 right-0 w-full h-full bg-transparent border-none focus:outline-none absoluter opacity-1'/>
            </div>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2927_12484)">
            <path d="M9.16663 1.66667C13.3066 1.66667 16.6666 5.02667 16.6666 9.16667C16.6666 13.3067 13.3066 16.6667 9.16663 16.6667C5.02663 16.6667 1.66663 13.3067 1.66663 9.16667C1.66663 5.02667 5.02663 1.66667 9.16663 1.66667ZM9.16663 15C12.3891 15 15 12.3892 15 9.16667C15 5.94334 12.3891 3.33334 9.16663 3.33334C5.94329 3.33334 3.33329 5.94334 3.33329 9.16667C3.33329 12.3892 5.94329 15 9.16663 15ZM16.2375 15.0592L18.595 17.4158L17.4158 18.595L15.0591 16.2375L16.2375 15.0592Z" fill="#1F4173"/>
            </g>
            <defs>
            <clipPath id="clip0_2927_12484">
            <rect width="20" height="20" fill="white"/>
            </clipPath>
            </defs>
            </svg>
        </div>
);
}

export default SearchInput;