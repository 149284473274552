import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import axiosRetry from "axios-retry";
import { AuthToken } from "./auth/auth-store";

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL

const token = AuthToken();

interface ApiResponse<T = any> {
  data: T;
}

class ApiSupport {
  async post<T>(url: string, data: any): Promise<string> {
    
    let headers : any = {
      'Content-Type': 'application/json',
      accept: "*/*",
    };

    token && (headers['Authorization'] = `Bearer ${token}`);

    const params: AxiosRequestConfig = {
      method: "POST",
      url: url,
      data: data,
      headers,
    };

    let response: AxiosResponse<ApiResponse<T>>;

    try {
      axiosRetry(axios, { retries: 1 });
      response = await axios.request<ApiResponse<T>>(params);
    } catch (e) {
      return 'error';
    }

    return JSON.stringify(response.data);
  }

  async get<T>(url: string): Promise<string> {

    let headers : any = {
      'Content-Type': 'application/json',
      accept: "*/*",
    };

    token && (headers['Authorization'] = `Bearer ${token}`);
    
    const params: AxiosRequestConfig = {
      method: "GET",
      url: url,
      headers,
    };

    let response: AxiosResponse<ApiResponse<T>>;

    try {
      //axiosRetry(axios, { retries: 0 });
      response = await axios.request<ApiResponse<T>>(params);
    } catch (e) {
      return 'error';
    }

    return JSON.stringify(response.data);
  }
}

export default ApiSupport;
