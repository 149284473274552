import React, {ReactNode} from 'react';
import SvgLogo from '../svg-logo';
import ImageCarousel from '../partials/guest-carousel-slider';

interface LayoutProps {
    children: ReactNode;
}

const GuestLayout : React.FC <LayoutProps>= ({children}) => {
    return(
        <div>
            <div className='grid md:grid-cols-2'>
                <div className='my-[5em]'>
                    <div className='max-w-sm p-10 mx-auto lg:p-0'>
                        <div className='flex items-center space-x-3'>
                            <SvgLogo/>
                            <h1 className='text-primary text-[1.3em] font-[400]'>cdash</h1>
                        </div>
                        <div className='mt-[5em]'>
                            {children}
                        </div>
                    </div>
                </div>
                <div className='hidden h-full lg:min-h-screen md:block'>
                    <ImageCarousel/>
                </div>
            </div>
        </div>
    );
}

export default GuestLayout;