import React from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ImageCarousel = () => {
    const settings = {
        dots: false,  // Show navigation dots
        infinite: true,  // Infinite loop
        speed: 500,  // Animation speed
        slidesToShow: 1,  // Number of images per view
        slidesToScroll: 1,  // Number of images to scroll per click
        autoplay: true,  // Enable autoplay
        autoplaySpeed: 4000,  // Autoplay speed in ms
        arrows:false,
    };

    const images = [
        '/images/money-background.svg',
        '/images/statue-background.svg',
    ];

    return (
        <div className="h-[100vh] overflow-hidden">
            <Slider {...settings}>
                {images.map((image, index) => (
                    <div key={index} className="relative h-[100vh]">
                        <img src={image} className="absolute top-0 left-0" alt={`Slide ${index}`} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default ImageCarousel;