import React, {useState, useEffect, useRef} from 'react';
import AuthLayout from '../../../components/layouts/auth';
import SearchInput from '../../../components/form/search-input';
import { CalendarDays, ChevronDown, Filter, Minus } from 'lucide-react';
import { Link } from 'react-router-dom';
import ApiSupport from '../../../lib/api-support';
import moment from 'moment';
import TablePagination from '../../../components/table/table-pagination';
import { numberWithCommas, TransactionTypes, getTransactionTypeName, getTransactionColorAndName } from '../../../lib/helper-support';
import TableSkeleton from '../../../components/loader/table-placeholder-loader';
import Naira from '../../../components/naira';
import { ErrorFlash, SuccessFlash } from '../../../components/helper/flash-notification';
import SpinnerLoader from '../../../components/loader/spinner-loader';



const Transaction: React.FC = () => {

    const [loadingTransactions, setLoadingTransactions] = useState<boolean>(true);

    const TransactionStatuses = ['all', 'success', 'failed', 'pending'];

    //const TransactionTypes = ['all', 'fee', 'withdraw cash', 'send cash (bank)', 'savings', 'airtime', 'internet data', 'send cash (wallet)'];

    const [showFilters, setShowFilters] = useState<boolean>(false);

    const showFiltersRef = useRef<HTMLDivElement>(null);

    const [selectedTransactions, setSelectedTransactions] = useState<string[]>([]);

    const [transactions, setTransactions] = useState<TransactionsProp[]>([]);

    const [transactionData, setTransactionData] = useState<TransactionDataProp | undefined>(undefined);

    const [loadingExport, setLoadingExport] = useState<boolean>(false);

    const [currentPage, setCurrentPage] = useState<number>(1);

    //filter parameters
    const [transactionStatus, setTransactionStatus] = useState<string>('all');
    const [transactionType, setTransactionType] = useState<string>('all');
    const [createdFrom, setCreatedFrom] = useState<string>(moment().startOf('M').format('YYYY-MM-DD'));
    const [createdTo, setCreatedTo] = useState<string>(moment().endOf('M').format('YYYY-MM-DD'));
    
    const transactionTypes = TransactionTypes;

    type TransactionsProp = {
        reference:string,
        date: string,
        type: string,
        sender: string,
        receiver: string | null,
        amount: string,
        status: string,
        statusColor: string,
    };

    type TransactionDataProp = {
        items:TransactionsProp [],
        page: number,
        nextPage: number,
        hasMorePages: boolean,
        totalPages: number,
        total?: number,
    };

    const ExportTransactions = async () => {
        let url = 'transactions/export';
        url += `?transaction_status=${transactionStatus}`;
        url += `&transaction_type=${transactionType}`;
        url += `&created_from=${createdFrom}`;
        url += `&created_until=${createdTo}`;
        setLoadingExport(true);
        const request = await (new ApiSupport()).post(url, []);
        setLoadingExport(false);

        if ( request === 'error' ){
            ErrorFlash('Something went wrong');
            return;
        }
        SuccessFlash('Transactions export processing');
    }

    const FetchTransaction = async () => {   
        setLoadingTransactions(true);
     
        let url = 'transactions';
        url += `?page=${currentPage}`;
        url += `&transaction_status=${transactionStatus}`;
        url += `&transaction_type=${transactionType}`;
        url += `&created_from=${createdFrom}`;
        url += `&created_until=${createdTo}`;
        const getTransactions = await (new ApiSupport()).get(url);

        if ( getTransactions === 'error' ){
            setLoadingTransactions(false);
            return;
        }

        const data = JSON.parse(getTransactions);

        setTransactionData(data);
          
        let newTransactions = data.items.map((transaction: any) => {
            return {
                date: moment(transaction.created_at).format('h:mm a, MMM D'), 
                reference:transaction.reference,
                type: getTransactionTypeName(transaction),
                sender: transaction.sender ? transaction.sender.name : <Minus/>,
                receiver: transaction.receiver ? transaction.receiver.name : <Minus/>,
                amount: numberWithCommas(transaction.amount),
                status: getTransactionColorAndName(transaction).name,
                statusColor: getTransactionColorAndName(transaction).color,
            };
        });

        setTransactions(newTransactions);
        setLoadingTransactions(false);
        window.scrollTo(0, 0);
        setCurrentPage(data.page);
    }

    useEffect(()=> {FetchTransaction()}, [currentPage, transactionStatus, transactionType, createdFrom, createdTo]);

    const onSelectedTransaction = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.target.checked 
            ? setSelectedTransactions([...selectedTransactions, event.target.value]) 
            : setSelectedTransactions(selectedTransactions.filter(item => item !== event.target.value));
    }

     const handleOnClickOutside = (event: MouseEvent) => {
        if (showFiltersRef.current && ! showFiltersRef.current.contains(event.target as Node))  onShowFilter('outsideEvent');
    }

    const onShowFilter = (triggerBy = 'default') => {
    
        const filters = document.getElementById('filters');
        
        if ( triggerBy === 'outsideEvent' ){
            filters?.classList.add(`-translate-y-5`);
            filters?.classList.add('opacity-0');
            filters?.classList.remove('z-20');
            setShowFilters(false);
            return;
        }
        
        filters?.classList.toggle(`-translate-y-5`);
        
        filters?.classList.toggle('opacity-0');
        
        filters?.classList.toggle('z-20');

        setShowFilters(!showFilters); 
    };

    const resetFilter = () => {
        setTransactionStatus('all');
        setTransactionType('all');
        setCurrentPage(1);
        setCreatedFrom(moment().startOf('M').format('YYYY-MM-DD'))
        setCreatedTo(moment().endOf('M').format('YYYY-MM-DD'))
    }

    useEffect(() => {
        // Add event listener when the component mounts
        document.addEventListener('click', handleOnClickOutside);
        
        // Cleanup event listener when the component unmounts
        return () => {
        document.removeEventListener('mousedown', handleOnClickOutside);
        };
      });

    return (
        <AuthLayout>
            <div>
                <div className='items-center justify-between space-y-5 md:flex md:space-y-0'>
                    <div className='flex items-center space-x-6'>
                        <h1 className='page-title'>Transactions</h1>
                        <span className='text-tertiary font-[600] text-[14px]'>{numberWithCommas(transactionData?.total ?? 0)} transactions</span>
                    </div>
                    <button onClick={() =>  ExportTransactions()} disabled={loadingExport} type="button" className={`${loadingExport && 'opacity-[0.5]'} px-8 text-white input bg-[#124BB0] w-fit hover:bg-tertiary shadow-sm flex items-center space-x-2`}>
                        <span>Export data</span>
                        {loadingExport && <SpinnerLoader/>}
                    </button>
                </div>
                <div className='relative'>
                    <div className='items-center justify-between w-full mt-2 space-y-5 lg:space-y-0 lg:flex'>
                        <SearchInput placeholder='Find Transaction'/>
                        <div ref={showFiltersRef}>
                            <div className='text-[13px] flex items-center space-x-5'>
                                <div onClick={() => onShowFilter()} className='z-10 flex items-center space-x-3 cursor-pointer'>
                                    <Filter size={13}/>
                                    <p className='text-main-dark font-[700]'>Filter by</p>
                                    <ChevronDown size={13}/>
                                </div>
                                <div className='flex items-center space-x-1 text-[#778CA2]'>
                                    <CalendarDays size={13}/>
                                    <p className='font-[500] uppercase'>date</p>
                                </div>
                                <div onClick={() => onShowFilter()} className='z-10 flex items-center space-x-3 cursor-pointer'>
                                    <p className='text-[#252631] font-[500] uppercase'>
                                        {moment(createdFrom).format('MMMM YYYY')}
                                    </p>
                                    <ChevronDown size={13}/>
                                </div>
                                
                            </div>
                            {/* filter */}
                            <div  id='filters' className={`transition-all duration-500 ease-in-out transform w-full lg:w-[25em] absolute -translate-y-5 opacity-0
                                                    border border-[#135EE5] p-5 rounded-[15px] bg-white   lg:right-0 lg:top-10 top-24`}>
                                    <div className='flex justify-between font-[500]'>
                                        <span>Filters</span>
                                        <span onClick={()=>resetFilter()} className='text-red-600 cursor-pointer'>Reset</span>
                                    </div>
                                    <div className='grid mt-5 gap-y-6'>
                                        <div>
                                            <h4 className='text-[15px] mb-2'>Transaction Status</h4>
                                            <div className='border input'>
                                                <select value={transactionStatus} onChange={(e) => [setTransactionStatus(e.target.value), setCurrentPage(1)]} className='w-full text-gray-600 focus:outline-none'>
                                                    {TransactionStatuses.map((status, index) => (
                                                        <option key={index} value={status}>{status}</option>
                                                    ))}
                                                </select>
                                                <ChevronDown size={13}/>
                                            </div>
                                        </div>
                                        <div>
                                            <h4 className='text-[15px] mb-2'>Transaction Type</h4>
                                            <div className='border input'>
                                                <select value={transactionType} onChange={(e) => [setTransactionType(e.target.value), setCurrentPage(1)]} className='w-full text-gray-600 focus:outline-none'>
                                                    <option value={'all'}>all</option>
                                                    {transactionTypes.map((item, index) => (
                                                        <option key={index} value={item.type}>{item.name}</option>
                                                    ))}
                                                </select>
                                                <ChevronDown size={13}/>
                                            </div>
                                        </div>
                                        <div>
                                            <h4 className='text-[15px] mb-2'>Created From</h4>
                                            <div className='border input'>
                                                <input value={createdFrom} onChange={(e) => setCreatedFrom(e.target.value)} className='w-full bg-transparent focus:outline-none' type='date' />
                                                {/* <CalendarDays size={13}/> */}
                                            </div>
                                        </div>
                                        <div>
                                            <h4 className='text-[15px] mb-2'>Created Until</h4>
                                            <div className='border input'>
                                                <input value={createdTo} onChange={(e) => setCreatedTo(e.target.value)} className='w-full bg-transparent focus:outline-none' type='date' />
                                                {/* <CalendarDays size={13}/> */}
                                            </div>
                                        </div>
                                    </div>
                            </div>
                            {/* --/ filter */}
                        </div>
                    </div>
                    {/* table */}
                    <div className='relative overflow-x-auto'>
                        <table className='mt-10 text-tertiary font-[600] text-[14px] w-full relative z-10'>
                            <thead>
                                <tr className='border-b border-[#E6EAF0]'>
                                    <td className="px-2 py-3">
                                        <input type='checkbox' />
                                    </td>
                                    <td className="px-4 py-3">Date</td>
                                    <td className="px-6 py-3">Type</td>
                                    <td className="px-6 py-3">Sender</td>
                                    <td className="px-6 py-3">Receiver</td>
                                    <td className="px-6 py-3">Amount</td>
                                    <td className="px-6 py-3">Status</td>
                                </tr>
                            </thead>
                            <tbody>
                                {loadingTransactions && <TableSkeleton numberOfColumns={7}/>}
                                {! loadingTransactions && transactions.map((transaction, index) => (
                                    <tr key={index} 
                                        className='border-b border-[#E6EAF0] relative'
                                        style={{ backgroundColor: selectedTransactions.includes(transaction.reference) ? '#E6EAF0' : '' }}
                                    >
                                        <td className="px-2 py-3">
                                            <input onChange={(event) => onSelectedTransaction(event)} value={transaction.reference} type='checkbox' className='relative z-10' />
                                            <Link to={`/transactions/${transaction.reference}`} className='absolute top-0 bottom-0 left-0 w-full h-full hover:bg-[#E6EAF0] opacity-[0.2]'/>
                                        </td>
                                        <td className="px-4 py-3">{transaction.date}</td>
                                        <td className="px-6 py-3">
                                            <div className='flex bg-[#F8FAFB] justify-center w-fit py-2 px-6 rounded-full'>
                                                    <span className='first-letter:capitalize'>{transaction.type}</span>
                                            </div>
                                        </td>
                                        <td className="px-6 py-3">{transaction.sender ?? <Minus size={14}/>}</td>
                                        <td className="px-6 py-3">{transaction.receiver ?? <Minus size={14}/>}</td>
                                        <td className="px-6 py-3">{<Naira/>} {transaction.amount}</td>
                                        <td className="px-6 py-3">
                                            <div className='flex items-center space-x-2 font-[400] bg-[#F8FAFB] justify-center w-[10em] py-2 rounded-full'>
                                                <span style={{ backgroundColor: transaction.statusColor }} className='w-[9px] h-[9px] rounded-full'></span>
                                                <span className='text-[#252631]'>{transaction.status}</span>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                                
                            </tbody>
                        </table>
                    </div>
                    {/* --/ table */}
                    {/* pagination */}
                    {! loadingTransactions && (
                        <div className='mt-5'>
                            <TablePagination 
                                totalPages={transactionData?.totalPages ?? 0} 
                                onPageChange={(page) => setCurrentPage(page)} 
                                currentPage={transactionData?.page}
                            />
                        </div>
                    )}
                    {/* --/ pagination */}
                </div>
            </div>
        </AuthLayout>
    );
}

export default Transaction;