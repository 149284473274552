import { Minus } from "lucide-react"

type TransactionLayoutType = {
    transaction: any
}


const BankTransactionLayout : React.FC <TransactionLayoutType> = ({transaction} : TransactionLayoutType) => {
    return (
        <div>
            <p>{transaction.receiver?.account_name}</p>
            <p>{transaction.receiver?.bank_name}</p>
            <p>{transaction.receiver?.account_number}</p>
        </div>
    )
}

const UserTransferLayout : React.FC<TransactionLayoutType>  = ({transaction} : TransactionLayoutType) => {
    return (
        <div>
            <p>{transaction.receiver.name}</p>
            <p>#{transaction.receiver.username}</p>
        </div>
    )
} 

const GetTransactionLayout : React.FC<TransactionLayoutType>  = ({transaction} : TransactionLayoutType) => {
    switch(transaction.type){
        case 'bank_transfer':
            return <BankTransactionLayout transaction={transaction} />
        case 'user_transfer':
        case 'fund_wallet':
            return <UserTransferLayout transaction={transaction} />
        default:
            return <Minus />
    }
}

export default GetTransactionLayout;