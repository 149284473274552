import React from "react";
import SvgLogo from "../components/svg-logo";

const NotFound : React.FC = () => {
    return(
        <div className="flex items-center justify-center h-screen text-white bg-primary">
           <div className="font-[700] text-[2em] md:text-[3em] text-center grid content-center justify-items-center">
                <span className="rounded-full shadow-xl">
                    <SvgLogo/>
                </span>
                <p className="font-[500] mb-3">Oops!!</p>
                Page Not Found
           </div>
        </div>
    );
}

export default NotFound;