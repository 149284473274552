type onChangeInputFunc = (e:React.ChangeEvent<HTMLInputElement>) => void

type InputType = {
    placeholder?:string,
    type?: 'text' | 'email' | 'number' | 'password',
    label?: string,
    id?: string,
    value?:string,
    error?:string|null,
    onChangeInput: onChangeInputFunc,
}

const InputField = ({
    placeholder,
    type = 'text',
    label,
    id,
    value,
    error,
    onChangeInput
} : InputType) => {
    const Id = id ?? label;

    return(
        <label htmlFor={Id} className="label">
            <span>{label}</span>
            <input 
                placeholder={placeholder ?? `Enter ${label}`} 
                type={type}
                className={`mt-2 input border-[#E8ECEF] border ${error && 'border-red-500'}`}
                onInput={onChangeInput}
            />
            {error && <span className="text-red-500 label">{error}</span>}
        </label>
    );
}

export default InputField;