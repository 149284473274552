import { Navigate, RouteObject } from "react-router-dom";
import Login from "../pages/guest/login";
import { AuthToken } from "../lib/auth/auth-store";

type RouteType = {
    path: string;
    element: React.ReactNode;
};

const routes: RouteType[] = [
    {path: '/login', element: <Login/>}
];

// Check if authenticated
  const middleware = () => {
    const isAuthenticated = Boolean(!!AuthToken());
    return routes.map((route) => ({
      path: route.path,
      element: ! isAuthenticated ? route.element : <Navigate to="/" />
    }));
  }

const Guest : RouteObject[] = middleware();

export default Guest;