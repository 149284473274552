import React, { FormEvent, useState, useRef } from "react";
import { ArrowLeft } from "lucide-react";
import SecondsCountdown from "../../../components/helper/seconds-countdown";
import NumericInput from "../../../components/form/numeric-input";

type LoginFormType = {
    onSubmitForm: (otp:string) => void,
    changeSection: (section:string) => void,
    resendOtp: () => Promise<boolean>,
    email:string,
    otpState: boolean,
    otpError: string | null,
}

const OtpForm = ({onSubmitForm, changeSection, resendOtp, email, otpError, otpState}:LoginFormType) => {
    const [showResend, setShowResend] = useState<boolean>(false);

    const countdownRef = useRef<{ resetTimer: () => void } | null>(null);
    
    const onResendOTP = async () => {
        const resendOTP = await resendOtp();
        if (resendOTP){
            countdownRef.current?.resetTimer();
            setShowResend(false);
        }
    }

    return(
        <div>
            <h3 onClick={()=> changeSection('form')} className="flex mb-10 font-[400] cursor-pointer">
                <ArrowLeft/>
                <span>Back</span>
            </h3>
            <h1 className="text-[#1B1E24] page-title font-[600]">
                Two factor authentication
            </h1>
            <p className="font-[400] text-[#252631] text-[14px] mt-3">
                An OTP has been sent to your email to verify your sign in attempt
            </p>
            {otpError && (<div className="p-4 mt-5 text-white bg-red-600 label">{otpError}</div>)}
            <div className="mt-10">
                <p className="font-[400] text-[#656565] text-[13px]">
                    Please enter the OTP sent to <span className="text-primary">{email}</span>
                </p>
                <div className="relative my-5">
                    <NumericInput onFilled={onSubmitForm} length={6}/>
                    {otpState && (<div className="absolute top-0 z-10 w-full h-full bg-gray-900 opacity-5"></div>)}
                </div>
                {otpState && <div className="my-5 text-yellow-800 label animate-pulse">verifying...</div>}
                <p className="font-[400] text-[14px]">
                    { showResend ? <span onClick={onResendOTP} className="cursor-pointer hover:underline">Resend new OTP</span> : <span>
                        Resend in <span className="text-primary"><SecondsCountdown ref={countdownRef} onComplete={() => setShowResend(true)} seconds={60}/></span>
                    </span> }
                </p>
            </div>
        </div>
    );
}

export default OtpForm