import React, {useEffect, useState, useRef} from 'react';
import AuthLayout from '../../components/layouts/auth';
import { ChevronDown, CalendarDays, Filter } from 'lucide-react';
import ApiSupport from '../../lib/api-support';
import { formatNumber, numberWithCommas } from '../../lib/helper-support';
import LineLoader from '../../components/loader/line-loader';
import MetricIndicator from '../../components/partials/metric-indicator';
import Naira from '../../components/naira';
import SpinnerLoader from '../../components/loader/spinner-loader';
import { ErrorFlash } from '../../components/helper/flash-notification';
import moment from 'moment';

const Dashboard: React.FC = () => {

    const [showFilters, setShowFilters] = useState<boolean>(false);

    const showFiltersRef = useRef<HTMLDivElement>(null);

    const [currentService, setCurrentService] = useState<string>('squad');
    
    const [currentServiceColor, setCurrentServiceColor] = useState<string>('#CF4306');

    const [pageLoading, setPageLoading] = useState<boolean>(true);

    const [loadingSettlementBalance, setLoadingSettlementBalance] = useState<boolean>(true);

    //filter parameters
    const [createdFrom, setCreatedFrom] = useState<string>(moment().startOf('M').format('YYYY-MM-DD'));
    const [createdTo, setCreatedTo] = useState<string>(moment().endOf('M').format('YYYY-MM-DD'));
    
    const [settlementBalance, setSettlementBalance] = useState<SettlementBalanceType>({
        balance: '0',
    } as SettlementBalanceType);

    type IndividualMetric = {
        count: string,
        percentage?: string,
        increase?: boolean,
        decrease?: boolean,
    }
    
    type MetricsType = {
        total_users: IndividualMetric,
        total_transactions: IndividualMetric,
        total_transaction_amount: IndividualMetric,
        total_revenue: IndividualMetric,
    }

    type SettlementBalanceType = {
        balance: string,
    }
    
    const [metrics, setMetrics] = useState<MetricsType>({
        total_users: {count:'0'} as IndividualMetric,
        total_transaction_amount: {count:'0'} as IndividualMetric,
        total_revenue: {count:'0'} as IndividualMetric,
        total_transactions: {count:'0'} as IndividualMetric,
    } as MetricsType);

     type ServicesType = {
        name: string,
        color: string,
     }; 

    const services : ServicesType[] = [
        {
            name: 'squad',
            color: '#CF4306',
        },
        {
            name: 'anchor',
            color: '#2DB744',
        },
        {
            name: 'source',
            color: '#0815C6',
        }
    ];

    const changeService = (index:any) => {
        const currentService = services[index];
        
        setCurrentService(currentService.name);
        
        setCurrentServiceColor(currentService.color);
    }

    const getMetrics = async () => {
        let url = '/metrics';
        
        url += `?created_from=${createdFrom}`;
        
        url += `&created_until=${createdTo}`;
        
        setPageLoading(true);
        
        const request = await new ApiSupport().get(url);

        setPageLoading(false);
        if ( request === 'error' ){
            ErrorFlash('Something went wrong');
            return;
        }
   
        setMetrics(JSON.parse(request));
    }

    const retrieveSettlementBalance = async () => {
        setLoadingSettlementBalance(true);
        
        const request = await new ApiSupport().get(`/retrieveSettlementBalance/${currentService}`);
        
        setLoadingSettlementBalance(false);
        
        if ( request === 'error' ){
            setSettlementBalance({balance: '0'} as SettlementBalanceType);
            ErrorFlash('Something went wrong');
            return;
        }
        
        setSettlementBalance(JSON.parse(request).data);
    }

    useEffect(() => {
        getMetrics(); 
    }, [createdFrom, createdTo]);

    useEffect(() => {retrieveSettlementBalance()}, [currentService]);

    const handleOnClickOutside = (event: MouseEvent) => {
        if (showFiltersRef.current && ! showFiltersRef.current.contains(event.target as Node))  onShowFilter('outsideEvent');
    }

    const onShowFilter = (triggerBy = 'default') => {
    
        const filters = document.getElementById('filters');
        
        if ( triggerBy === 'outsideEvent' ){
            filters?.classList.add(`-translate-y-5`);
            filters?.classList.add('opacity-0');
            filters?.classList.remove('z-20');
            setShowFilters(false);
            return;
        }
        
        filters?.classList.toggle(`-translate-y-5`);
        
        filters?.classList.toggle('opacity-0');
        
        filters?.classList.toggle('z-20');

        setShowFilters(!showFilters); 
    };

    const resetFilter = () => {
        setCreatedFrom(moment().startOf('M').format('YYYY-MM-DD'))
        setCreatedTo(moment().endOf('M').format('YYYY-MM-DD'))
    }

    useEffect(() => {
        // Add event listener when the component mounts
        document.addEventListener('click', handleOnClickOutside);
        
        // Cleanup event listener when the component unmounts
        return () => {
        document.removeEventListener('mousedown', handleOnClickOutside);
        };
      });
    
    return (
        <AuthLayout>
            <div className='relative items-center justify-between space-y-5 md:flex md:space-y-0'>
                <h1 className='page-title'>Dashboard</h1>
                <div ref={showFiltersRef}>
                            <div className='text-[13px] flex items-center space-x-5'>
                                <div onClick={() => onShowFilter()} className='z-10 flex items-center space-x-3 cursor-pointer'>
                                    <Filter size={13}/>
                                    <p className='text-main-dark font-[700]'>Filter by</p>
                                    <ChevronDown size={13}/>
                                </div>
                                <div className='flex items-center space-x-1 text-[#778CA2]'>
                                    <CalendarDays size={13}/>
                                    <p className='font-[500] uppercase'>date</p>
                                </div>
                                <div onClick={() => onShowFilter()} className='z-10 flex items-center space-x-3 cursor-pointer'>
                                    <p className='text-[#252631] font-[500] uppercase'>
                                        {moment(createdFrom).format('MMMM YYYY')}
                                    </p>
                                    <ChevronDown size={13}/>
                                </div>
                                
                            </div>
                            {/* filter */}
                            <div  id='filters' className={`transition-all duration-500 ease-in-out transform w-full lg:w-[25em] absolute -translate-y-5 opacity-0
                                                    border border-[#135EE5] p-5 rounded-[15px] bg-white   lg:right-0 lg:top-10 top-24`}>
                                    <div className='flex justify-between font-[500]'>
                                        <span>Filters</span>
                                        <span onClick={()=>resetFilter()} className='text-red-600 cursor-pointer'>Reset</span>
                                    </div>
                                    <div className='grid mt-5 gap-y-6'>
                                        <div>
                                            <h4 className='text-[15px] mb-2'>Created From</h4>
                                            <div className='border input'>
                                                <input value={createdFrom} onChange={(e) => setCreatedFrom(e.target.value)} className='w-full bg-transparent focus:outline-none' type='date' />
                                                {/* <CalendarDays size={13}/> */}
                                            </div>
                                        </div>
                                        <div>
                                            <h4 className='text-[15px] mb-2'>Created Until</h4>
                                            <div className='border input'>
                                                <input value={createdTo} onChange={(e) => setCreatedTo(e.target.value)} className='w-full bg-transparent focus:outline-none' type='date' />
                                                {/* <CalendarDays size={13}/> */}
                                            </div>
                                        </div>
                                    </div>
                            </div>
                            {/* --/ filter */}
                        </div>
            </div>
            {pageLoading && <LineLoader/>}
            { metrics && (
                <div>
                <div className='grid gap-4 mt-10 lg:grid-cols-3 md:grid-cols-2'>
                    <div className='p-5 bg-primary-light rounded-[13px] pb-10 h-[10em] relative md:h-[12em]'>
                        <div className='flex items-center justify-between'>
                            <h4 className='font-[600] text-[13px]'>Total Users</h4>
                            {metrics.total_users.percentage && (
                                <MetricIndicator 
                                    percentage={metrics.total_users.percentage}
                                    increase={metrics.total_users.increase}
                                    decrease={metrics.total_users.decrease}
                                />
                            )}
                        </div>
                        <div className='absolute top-14'>
                            <h4 className='text-blue-black font-[600] md:text-[3em] text-[2.5em]'>
                                {numberWithCommas(metrics.total_users.count)}
                            </h4>
                        </div>
                    </div>
                    <div className='p-5 bg-primary-light rounded-[13px] pb-10 relative h-[10em] md:h-[12em]'>
                        <div className='flex items-center justify-between'>
                            <h4 className='font-[600] text-[13px]'>Total Transactions</h4>
                            {metrics.total_transactions.percentage && (
                                <MetricIndicator 
                                    percentage={metrics.total_transactions.percentage}
                                    increase={metrics.total_transactions.increase}
                                    decrease={metrics.total_transactions.decrease}
                                />
                            )}
                        </div>
                        <div className='absolute top-14'>
                            <h4 className='text-blue-black font-[600] md:text-[3em] text-[2.5em]'>
                                {numberWithCommas(metrics.total_transactions.count)}
                            </h4>
                        </div>
                    </div>
                    <div className='p-5 bg-primary-light rounded-[13px] relative h-[10em] md:h-[12em]'>
                        <div className='flex items-center justify-between'>
                            <h4 className='font-[600] text-[13px]'>Total Transaction <br/> Volumes</h4>
                            {metrics.total_transaction_amount.percentage && (
                                <MetricIndicator 
                                    percentage={metrics.total_transaction_amount.percentage}
                                    increase={metrics.total_transaction_amount.increase}
                                    decrease={metrics.total_transaction_amount.decrease}
                                />
                            )}
                        </div>
                        <div className='absolute top-14'>
                            <h4 className='text-blue-black font-[600] md:text-[3em] text-[2.5em]'><Naira/>{formatNumber(metrics.total_transaction_amount.count)}</h4>
                            <p className='text-gray-shade text-[13px] font-[600] mt-2 flex items-center'>
                                <Naira/>{numberWithCommas(metrics.total_transaction_amount.count)}.<span className='text-[11px]'>{numberWithCommas(metrics.total_transaction_amount.count,true, true)}</span>
                            </p>
                        </div>
                    </div>
                    <div className='p-5 bg-primary-light rounded-[13px] relative h-[10em] md:h-[12em]'>
                        <div className='flex items-center justify-between'>
                            <h4 className='font-[600] text-[13px]'>Total Revenue</h4>
                            {metrics.total_revenue.percentage && (
                                <MetricIndicator 
                                    percentage={metrics.total_revenue.percentage}
                                    increase={metrics.total_revenue.increase}
                                    decrease={metrics.total_revenue.decrease}
                                    
                                />
                            )}
                        </div>
                        <div className='absolute top-14'>
                            <h4 className='text-blue-black font-[600] md:text-[3em] text-[2.5em]'><Naira/>{formatNumber(metrics.total_revenue.count)}</h4>
                            <p className='text-gray-shade text-[13px] font-[600] mt-2'>
                                <Naira/>{numberWithCommas(metrics.total_revenue.count)}.<span className='text-[11px]'>{numberWithCommas(metrics.total_revenue.count,true, true)}</span>
                            </p>
                        </div>
                    </div>
                    <div className='p-5 bg-primary-light rounded-[13px] relative h-[10em] md:h-[12em]'>
                        <div className='flex items-center justify-between'>
                            <h4 className='font-[600] text-[13px]'>Settlement account <br/> balance</h4>
                        </div>
                        <div className='absolute left-0 right-0 w-full px-5 top-14'>
                            <h4 className='text-blue-black font-[600] md:text-[3em] text-[2.5em]'><Naira/>{formatNumber(settlementBalance.balance)}</h4>
                            <div className='flex items-center justify-between w-full'>
                                <p className='text-gray-shade text-[13px] font-[600] mt-2'>
                                <Naira/>{numberWithCommas(settlementBalance.balance)}.<span className='text-[11px]'>{numberWithCommas(settlementBalance.balance,true, true)}</span>
                                </p>
                                <label style={{backgroundColor: currentServiceColor}} className='uppercase text-white py-2 lg:px-10 px-3 rounded-full text-[13px] flex items-center space-x-2 w-fit relative'>
                                    <span>{currentService}</span>
                                    <select onChange={(e)=> changeService(e?.target?.value)} className='absolute top-0 bottom-0 left-0 right-0 w-full h-full uppercase bg-transparent opacity-0 cursor-pointer focus:outline-none'>
                                        {services.map((service, index) => (
                                            <option key={index} value={index}>{service.name}</option>
                                        ))}
                                    </select>
                                    <ChevronDown size={13}/>
                                    {loadingSettlementBalance && <SpinnerLoader/>} 
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            ) }
        </AuthLayout>
    );
}

export default Dashboard;