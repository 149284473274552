import React from "react";

type SkeletonType = {
    numberOfRows?: number,
    numberOfColumns: number,
}

const TableSkeleton : React.FC<SkeletonType> = ({numberOfRows = 1, numberOfColumns} : SkeletonType) => {
    const rows  = Array.from({length:numberOfRows})
    const columns  = Array.from({length:numberOfColumns})
    return(
        <>
        {rows.map((item:any, index:number) => (
            <tr key={index}>
                {columns.map((item:any, index:number) => (
                    <td key={index} className="p-2">
                        <div className="w-full h-1 bg-gray-200"></div>
                    </td>
                ))}
            </tr>
        ))}
        </>
    )
}

export default TableSkeleton;