import React, { FormEvent, useState } from "react";
import { Link } from "react-router-dom";
import InputField from "../../../components/form/input-field";
import SpinnerLoader from "../../../components/loader/spinner-loader";

type LoginFormType = {
    onSubmitForm: (data:{email:string, password:string}) => void;
    loginState: boolean;
    loginError: string | null,
}

const LoginForm = ({onSubmitForm, loginState, loginError}:LoginFormType) => {
    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')

    const [emailError, setEmailError] = useState<string|null>(null)
    const [passwordError, setPasswordError] = useState<string|null>(null)

    const [loading, setLoading] = useState<boolean>(false)

    const submitForm = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        ! email ? setEmailError('Email is required') : setEmailError(null);
        ! password ? setPasswordError('Password is required') : setPasswordError(null);
        
        setLoading(true);

        if ( ! email || ! password ) {
            setLoading(false);
            return;
        }

        setLoading(false);

        onSubmitForm({email, password});
    }

    return(
        <form onSubmit={submitForm}>
                <h1 className="text-[#1B1E24] page-title">Sign in</h1>
                <p className="font-[400] text-[#252631] text-[14px] mt-3">
                    Please sign in to the cdash to access all metrics, transactions and settings
                </p>
                {loginError && (<div className="p-4 mt-5 text-white bg-red-600 label">{loginError}</div>)}
                <div className="grid mt-10 gap-y-6">
                    <div>
                        <InputField 
                            label="Your email"
                            type='email'
                            onChangeInput={(e) => [setEmail(e.target.value), setEmailError(null)]}
                            error={emailError}
                        />
                    </div>
                    <div>
                        <InputField 
                            label="Password" 
                            placeholder="Enter your password"
                            type='password'
                            onChangeInput={(e) => [setPassword(e.target.value), setPasswordError(null)]}
                            error={passwordError}
                        />
                    </div>
                    <div className="flex items-center justify-between">
                        <label className="flex items-center space-x-2 cursor-pointer">
                            <input type="checkbox" />
                            <span className="label text-[#778CA2]">Remember me</span>
                        </label>
                        <Link to={'/login'} className="label text-tertiary hover:underline">Recover password</Link>
                    </div>
                    <button disabled={loading || loginState} className={`text-white flex space-x-2 uppercase input bg-primary text-[14px] font-[400] justify-center ${loading || loginState ? 'opacity-[0.5]' : 'hover:bg-tertiary'}`}>
                        <span>sign in</span>
                        {(loading || loginState) && <SpinnerLoader/>}
                    </button>
                </div>
            </form>
    );
}

export default LoginForm